import axios, { AxiosResponse } from 'axios'

export default class Api {
  service: any

  constructor () {
    this.service = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      withCredentials: false
    })
  }

  /*
  * Chefs
  */
  async getChefs () {
    return this.service.get('/Chefs/')
  }

  async getBooking (code: string): Promise<AxiosResponse<any>> {
    return this.service.get(`/Bookings?code=${code}`)
  }
}
