import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'

import Stream from '../views/Stream.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:code?',
    name: 'stream',
    component: Stream
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
