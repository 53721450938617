
import AgoraRTC from 'agora-rtc-sdk'
import RTCClient from '@/helpers/agora-rtc-client'
import KStreamPlayer from '@/components/KStreamPlayer.vue'
import KButton from '@/components/KButton.vue'
import { ref, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Api from '@/helpers/api'
import { formatDistance, differenceInMinutes } from 'date-fns'
import { nb } from 'date-fns/locale'
import { ElMessage, ElLoading } from 'element-plus'

export default {
  name: 'Stream',
  components: {
    KStreamPlayer
  },
  setup () {
    const api = new Api()
    const rtc = ref(new RTCClient())
    const route = useRoute()
    const router = useRouter()
    const remoteStreams = ref<Array<any>>([])
    const localStream = ref<any>(null)
    const disableJoin = ref(false)
    const options = ref({
      appid: process.env.VUE_APP_AGORA_APP_ID,
      token: null,
      uid: null,
      channel: null
    })
    const isMuted = ref(false)
    const joinedEvent = ref(false)
    const bookingCode = ref('')
    const isLoadingBooking = ref(false)
    const cameras = ref<Array<any>>([])

    let currentCameraId: string | null = null

    const remoteStream = computed(() => {
      if (remoteStreams.value.length > 0) {
        return remoteStreams.value[0]
      }
    })

    rtc.value.on('stream-added', (evt: any) => {
      const { stream } = evt
      rtc.value.client?.subscribe(stream)
    })

    rtc.value.on('stream-subscribed', (evt: any) => {
      const { stream } = evt
      if (!remoteStreams.value.find(it => it.getId() === stream.getId())) {
        remoteStreams.value.push(stream)
      }
    })

    rtc.value.on('stream-removed', (evt: any) => {
      const { stream } = evt
      remoteStreams.value = remoteStreams.value.filter(
        it => it.getId() !== stream.getId()
      )
    })

    rtc.value.on('peer-online', (evt: any) => {
      console.log('peer is online')
    })

    rtc.value.on('peer-leave', (evt: any) => {
      console.log('peer leave')
      remoteStreams.value = remoteStreams.value.filter(
        it => it.getId() !== evt.uid
      )
    })

    function getDevices () {
      AgoraRTC.getDevices(function (devices) {
        cameras.value = devices.filter(device => device.kind === 'videoinput')
      })
    }

    function joinEvent () {
      rtc.value
        .joinChannel(options.value)
        .then(() => {
          rtc.value
            .publishStream()
            .then((stream: any) => {
              localStream.value = stream
              joinedEvent.value = true
              getDevices()
            })
            .catch((err: any) => {
              console.log('publish local error', err)
            })
        })
        .catch((err: any) => {
          console.error(err)
        })
      disableJoin.value = true
    }

    function leaveEvent () {
      disableJoin.value = false
      rtc.value
        .leaveChannel()
        .then(() => {
          joinedEvent.value = false
          router.push({
            path: '/'
          })
        })
        .catch((err: any) => {
          console.log('leave error', err)
        })
      localStream.value = null
      remoteStreams.value = []
    }

    function switchDevice () {
      if (cameras.value && cameras.value.length > 0) {
        localStream.value.getVideoTrack().stop()

        const nextCameraId = () => {
          if (currentCameraId === null) {
            return cameras.value.find(c => c.label !== localStream.value?.videoName).deviceId
          }

          return cameras.value.find(c => c.deviceId !== currentCameraId).deviceId
        }

        const cameraId = nextCameraId()
        currentCameraId = cameraId
        localStream.value.switchDevice('video', cameraId)
      }
    }

    function mute () {
      if (isMuted.value) {
        localStream.value.unmuteAudio()
      } else {
        localStream.value.muteAudio()
      }

      isMuted.value = !isMuted.value
    }

    async function findBooking (code: string) {
      const loading = ElLoading.service({
        lock: true,
        text: 'Laster inn..'
      })
      isLoadingBooking.value = true

      const closeLoading = () => {
        isLoadingBooking.value = false
        loading.close()
      }

      const booking = await api.getBooking(code)
      if (booking.status !== 200) {
        ElMessage({
          message: 'Fant ingen booking',
          type: 'error',
          duration: 7000
        })
        closeLoading()
        return
      }

      const start = Date.parse(booking.data.start)
      const now = Date.now()
      const minutesToBooking = differenceInMinutes(start, now)

      if (minutesToBooking > 10) {
        const timeToBooking = formatDistance(start, now, { locale: nb })
        ElMessage({
          message: `Det er ${timeToBooking} til sesjonen starter.`,
          type: 'warning',
          duration: 20000
        })
      }

      if (minutesToBooking < -20) {
        ElMessage({
          message: 'Din booking har utgått',
          type: 'error',
          duration: 7000
        })
        closeLoading()
        return
      }

      router.push({
        path: `/${code}`
      })

      const token = booking.data.agoraToken
      options.value.token = token
      options.value.channel = booking.data.channelName

      joinEvent()

      window.setTimeout(() => {
        closeLoading()
      }, 1500)
    }

    function checkBookingParam () {
      const bookingCodeParam = route.params.code
      if (!bookingCodeParam) {
        return
      }

      bookingCode.value = bookingCodeParam.toString()
      findBooking(bookingCode.value)
    }

    onMounted(() => {
      checkBookingParam()
    })

    return {
      options,
      localStream,
      remoteStream,
      disableJoin,
      joinEvent,
      leaveEvent,
      switchDevice,
      mute,
      isMuted,
      bookingCode,
      findBooking,
      joinedEvent,
      isLoadingBooking
    }
  }
}
