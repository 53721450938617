import Api from '@/helpers/api'
import { createStore } from 'vuex'

const api = new Api()

export default createStore({
  state: {
    chefs: []
  },
  mutations: {
    SET_CHEFS (state, chefs) {
      state.chefs = chefs
    }
  },
  actions: {
    async getChefs (context) {
      const chefs = await api.getChefs()
      if (chefs.status !== 200) {
        throw new Error(chefs)
      }

      context.commit('SET_CHEFS', chefs.data)
    }
  },
  modules: {
  }
})
