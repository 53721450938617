<template>
  <div
    :id="domId"
    ref="player"
    class="kstream__player"
  />
</template>

<script>
import { defineComponent, onMounted, nextTick, onBeforeMount } from 'vue'

export default defineComponent({
  name: 'KStreamPlayer',
  props: {
    stream: {
      type: Object,
      default: null
    },
    domId: {
      type: String,
      default: null
    }
  },
  setup (props) {
    onBeforeMount(() => {
      if (props.stream) {
        if (props.stream.isPlaying()) {
          // props.stream.stop()
        }

        // props.stream.close()
      }
    })

    onMounted(async () => {
      await nextTick()
      if (props.stream && !props.stream.isPlaying()) {
        props.stream.play(`${props.domId}`, { fit: 'cover' }, (err) => {
          if (err && err.status !== 'aborted') {
            console.warn('trigger autoplay policy')
          }
        })
      }
    })
  }
})
</script>

<style>
.kstream__player {
  height: 100%;
  width: 100%;
}
</style>
