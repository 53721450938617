<template>
  <main class="content">
    <router-view />
  </main>
</template>

<script lang="js">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'App'
})
</script>

<style lang="scss">
#kokkehjelp {
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  height: 100%;
}

main {
  flex: 1 0 auto;
}
</style>
